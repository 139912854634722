import { Link } from 'gatsby'
import * as React from 'react'
import Layout from '../Layout'
import SEO from '../SEO'

const NotFoundPage = () => {
    return (
        <div>
            <Layout>
                <SEO title="Page not found" />
                <div className="notfound">
                    <span>404</span>
                    <p>Diese Seite konnte leider nicht gefunden werden.</p>
                    <p>
                        <Link to="/">Zurück zur Startseite</Link>
                    </p>
                </div>
            </Layout>
        </div>
    )
}

export default NotFoundPage
